import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DatabaseService } from '../../_services/DatabaseService';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
    selector: 'app-change-status',
    templateUrl: './change-status.component.html',
})
export class ChangeStatusComponent implements OnInit {
    
    data: any = {};
    loading_list: any = false;
    mode: any;
    savingData = false;
    gift_id;
    offer: any = {};
    userData: any = {};
    target: any
    dealer_id: any = '';
    district: any = '';
    wallet_no: any;
    redeem_type: any;
    constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router, public dialog: DialogComponent,
        @Inject(MAT_DIALOG_DATA) public model_data: any, public dialogRef: MatDialogRef<ChangeStatusComponent>) {
            console.log(model_data);
            
            this.data.id = model_data.id;
            this.userData.dealer_id = model_data.dealer_id
            this.dealer_id = model_data.dealer_id
            if (this.userData.dealer_id) {
                this.getDealerList(this.dealer_id);
            }
            this.district = model_data.district
            this.target = model_data.target;
            console.log(this.target);
            
            this.data.gift_status = model_data.status;
            this.data.status = model_data.status;
            this.wallet_no = model_data.wallet_no;
            this.redeem_type = model_data.redeem_type;
            
            
            
        }
        ngOnInit() {
            this.route.params.subscribe(params => {
                this.gift_id = params['gift_id'];
                
                
                console.log("Dealer id is", this.dealer_id)
                
                this.offer.gift_status = this.data.gift_status;
                this.userData.status = this.data.status;
                this.getDealerList(this.dealer_id);
                
                console.log(this.userData);
                
            });
        }
        
        redeemUpdate(form: any) {
            
            if(this.offer.gift_status == 'Approved'){
                if (this.redeem_type == 'Bank' && this.wallet_no == '') {
                    this.dialog.error('Wallet number dose not exist');
                    return;
                }
                else 
                {
                    this.savingData = true;
                    this.db.post_rqst({ 'status': this.offer, 'id': this.data.id }, 'offer/redeemStatus')
                    .subscribe(d => {
                        this.savingData = false;
                        this.dialog.success('Status successfully Change');
                        this.dialogRef.close(true);
                    });
                }
            }
            else 
            {
                this.savingData = true;
                this.db.post_rqst({ 'status': this.offer, 'id': this.data.id }, 'offer/redeemStatus')
                .subscribe(d => {
                    this.savingData = false;
                    this.dialog.success('Status successfully Change');
                    this.dialogRef.close(true);
                });
            }
        }
        
        
        
        statusUpdate(form: any) {
            console.log(this.userData);
            this.savingData = true;
            this.db.post_rqst({ 'status': this.userData.status, 'reason': this.userData.reason, 'id': this.data.id, 'dealer_id': this.userData.dealer_id }, 'karigar/karigarStatus')
            .subscribe(d => {
                this.savingData = false;
                this.dialog.success('Status successfully Change');
                this.dialogRef.close(true);
                console.log(d);
            });
        }
        dealer_list: any[];
        getDealerList(dealer_id) {
            this.db.post_rqst({ "district": this.district }, 'app_karigar/dealer_list')
            .subscribe(d => {
                console.log(d);
                this.dealer_list = d['dealers'];
            });
        }
        
        
        onNoClick(): void {
            this.dialogRef.close();
        }
    }
    