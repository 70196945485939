import { Component, OnInit } from '@angular/core';
import {DatabaseService} from '../../_services/DatabaseService';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogComponent} from '../../dialog/dialog.component';
import {SessionStorage} from '../../_services/SessionService';
import {MatPaginator, MatTableDataSource, MatDialog, MatDatepicker} from '@angular/material';

@Component({
  selector: 'app-dealer-add',
  templateUrl: './dealer-add.component.html',
  styleUrls: ['./dealer-add.component.scss']
})
export class DealerAddComponent implements OnInit {

  loading_list = false;
  karigarform: any = {};
  savingData = false;
  states: any = [];
  districts: any = [];
  cities: any = [];
  pincodes: any = [];
  karigar_id:any;
  date1:any;
  data: any;
  doc_id:any;
  url:any;
  
  
  constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router, public ses: SessionStorage,public matDialog: MatDialog,  public dialog: DialogComponent) { this.date1 = new Date();}
  
  ngOnInit() {
      this.route.params.subscribe(params => {
          this.karigar_id = params['dealer_id'];
          this.doc_id = params['dealer_id'];
          this.url = this.db.uploadUrl
          this.getDistrictList('');
          
          if (this.karigar_id)
          {
              this.getKarigarDetails();
              
          }
          this.getStateList();
          this.AssignSaleUser();
          this.AssignDistributor();
          this.get_karigar_type();
          this.getaddress('');

          this.karigarform.country_id = 99;
      });
  }
  
  openDatePicker(picker : MatDatepicker<Date>)
  {
      picker.open();
  }
  
  getData:any = {};
  getKarigarDetails() {
      this.loading_list = true;
      this.db.post_rqst(  {'karigar_id':this.karigar_id}, 'karigar/karigarDetail')
      .subscribe(d => {
          this.loading_list = false;
          console.log(d);
          this.karigarform = d.karigar;
          console.log( this.karigarform);
          this.getDistrictList('');
          this.getCityList('');
      });
  }
  
  dealer_list:any= [];

  getaddress(pincode)
  {
      if(this.karigarform.pincode.length=='5')
      {
          this.db.post_rqst({'pincode':pincode},'app_karigar/getAddress')
          .subscribe( (result) =>
          {
              console.log(result);
              var address = result.address;
              if(address!= null)
              {
                  // this.getCityList(this.karigarform.district);
                  this.karigarform.district = result.address.district_name;
                  this.karigarform.city = result.address.city;
                  this.cities = [result.address];
                  console.log(this.karigarform.city);
                  console.log(this.cities);
              }
          });
      }
      
  }


  getDealerList(val){
    this.db.post_rqst( { "district":val }, 'app_karigar/dealer_list')
    .subscribe(d => {
      console.log(d);
      this.dealer_list = d['dealers'];   
    });
}


  type_list = [];
  get_karigar_type()
  {
      this.db.post_rqst({},"karigar/get_kar_type")
      .subscribe(resp=>{
          console.log(resp);
          this.type_list = resp.types;
      })
  }
  
  getStateList(){
      this.loading_list = true;
      this.db.get_rqst('', 'app_master/getStates')
      .subscribe(d => {  
          this.loading_list = false;  
          this.states = d.states;
      });
  }
//   getDistrictList(val){
//       this.loading_list = true;
//       let st_name;
//       if(val == 1)
//       {
//           st_name = this.karigarform.state;
//       }
//       this.db.post_rqst({'state_name':st_name}, 'app_master/getDistrict')
//       .subscribe(d => {  
//           this.loading_list = false;
//           this.districts = d.districts;  
//       });
//   }

getDistrictList(val){
    console.log("you district_name : "+val);
    this.loading_list = true;
    let st_name;
    if(val == 1)
    {
        st_name = this.karigarform.state;
    }
    this.db.post_rqst({'district_name':val}, 'app_master/getDistrict')
    .subscribe(d => {  
        this.loading_list = false;
        this.districts = d.districts;  
        
    });
}

  getCityList(val){   
      this.loading_list = true;
      let dist_name;
    //   if(val == 1)
    //   {
          dist_name = this.karigarform.district;
          this.getDealerList(dist_name);
    //   }
      this.db.post_rqst({'district_name':dist_name,'city':val }, 'app_master/getCity')
      .subscribe(d => {  
          this.loading_list = false;
          this.cities = d.cities;
        //   this.karigarform.pincode = d.pins.pincode;
      });
    //   this.data.district =this.data.district.district_name;
    //   this.data.city =this.data.city.city;
  }




  pincode:any = [];
  getPincode(city_name){
      console.log(city_name);
      
      this.db.post_rqst({'city_name':city_name}, 'app_master/getPincodes')
      .subscribe(d => {  
          this.pincode = d.pins.pincode;
          this.karigarform.pincode = this.pincode;
          console.log( this.karigarform.pincode);
          

      });
  }









//   pincode:any = [];
//   getPincodeList(val){   
//       this.loading_list = true;
//       let pincode_name;
//       if(val == 1)
//       {
//           pincode_name = this.karigarform.pincode;
//       }
//       this.db.post_rqst({'city_name':pincode_name}, 'app_master/getPincodes')
//       .subscribe(d => {  
//           this.loading_list = false;
//           this.pincode = d.pins;
//       });
//   }



//   numeric_Number(event: any) {
//       const pattern = /[0-9\+\-\ ]/;
//       let inputChar = String.fromCharCode(event.charCode);
//       if (event.keyCode != 8 && !pattern.test(inputChar)) {
//           event.preventDefault();
//       }
//   }



numeric_Number(event: any) {


    console.log("value is get ==>",this.karigarform.document_no.length)
    if(this.karigarform.document_type == 'Adharcard'){

        if(this.karigarform.document_no.length == 4 || this.karigarform.document_no.length == 9){
            this.karigarform.document_no = this.karigarform.document_no.concat('-')
            console.log("add space===>",this.karigarform.document_no )
        }
    }

    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
    }
}

  savekarigarform(form:any)
  {
      this.savingData = true;
      this.loading_list = true;
      this.karigarform.dob = this.karigarform.dob  ? this.db.pickerFormat(this.karigarform.dob) : '';
      this.karigarform.created_by = this.db.datauser.id;
      if(this.karigar_id)
      {
          this.karigarform.karigar_edit_id = this.karigar_id;
      }
      else
      {
          this.karigarform.karigar_type = 2;
      }
      this.db.insert_rqst( { 'karigar' : this.karigarform }, 'karigar/addKarigar')
      .subscribe( d => {
          this.savingData = false;
          this.loading_list = false;
          console.log( d );
          if(d['status'] == 'EXIST' ){
              this.dialog.error( 'Email or Mobile No. exists');
              return;
          }
          this.router.navigate(['dealer-list/1']);
          this.dialog.success('Dealer has been successfully added');
      });
  }
  sales_users:any=[];
  AssignSaleUser()
  {
      this.loading_list = true;
      this.db.get_rqst('','karigar/sales_users')
      .subscribe(d => {
          this.loading_list = false;
          this.sales_users = d.sales_users;
      });
  }
  
  dr_list:any=[];
  AssignDistributor()
  {
      this.loading_list = true;
      this.db.get_rqst('', 'karigar/get_distributor')
      .subscribe(d => {
          console.log(d);
          this.loading_list = false;
          this.dr_list = d.dr_list;
      });
  }
  
  documentChange()
  {
      this.karigarform.document_no=' ';
  }
  onUploadChange(evt: any) {
      const file = evt.target.files[0];
      console.log(file);
      if (file) {
          const reader = new FileReader();
          reader.onload = this.handleReaderLoaded.bind(this);
          reader.readAsBinaryString(file);
          this.doc_id ='';
      }
  }
  handleReaderLoaded(e) {
    // if(this.karigarform.document_image){
        this.karigarform.document_image = 'data:image/png;base64,' + btoa(e.target.result) ;
        console.log( this.karigarform.document_image );
    // }
    // else if(this.karigarform.pan_image){
    //     this.karigarform.pan_image = 'data:image/png;base64,' + btoa(e.target.result) ;
    //     console.log( this.karigarform.pan_image );
    // }
     
  }

  onUploadChange2(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded2.bind(this);
      reader.readAsBinaryString(file);
      this.doc_id ='';
    }
  }
  
  
  handleReaderLoaded2(e) {
    this.karigarform.pan_image = 'data:image/png;base64,' + btoa(e.target.result) ;
    console.log(this.karigarform.pan_image);
    
  }


  selectSales()
  {
      this.karigarform.sales_mobile = this.sales_users.filter( x => x.id === this.karigarform.sales_user )[0].phone;
  }
  
  selectDistributor()
  {
      this.karigarform.dis_mobile = this.dr_list.filter( x => x.id === this.karigarform.dis_id )[0].phone;
  }
}


