import { DatabaseService } from './../_services/DatabaseService';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master-tab',
  templateUrl: './master-tab.component.html',
  styleUrls: ['./master-tab.component.scss']
})
export class MasterTabComponent implements OnInit {

  constructor(public db: DatabaseService) { }

  ngOnInit() {
  }

}
