import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../dialog/dialog.component';
import { DatabaseService } from '../_services/DatabaseService';

@Component({
  selector: 'app-update-wallet-detail',
  templateUrl: './update-wallet-detail.component.html'
})
export class UpdateWalletDetailComponent implements OnInit {
  savingData:boolean = false;
  data: any = {};
  
  
  constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router, public dialog: DialogComponent,
    @Inject(MAT_DIALOG_DATA) public model_data: any, public dialogRef: MatDialogRef<UpdateWalletDetailComponent>) { 
      console.log('====================================');
      console.log(model_data);
      console.log('====================================');
      this.data = model_data;
      console.log('====================================');
      console.log(this.data);
      console.log('====================================');
      
    }
    
    ngOnInit() {
    }
    
    numeric_Number(event: any) {
      const pattern = /[0-9\+\-\ ]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
    
    Update(form: any) {
      this.savingData = true;
      this.db.post_rqst({ 'wallet_no': this.data.wallet_no, 'karigar_id': this.data.id }, 'app_karigar/updateBankDetails')
      .subscribe(d => {
        this.savingData = false;
        this.dialog.success('Wallet Update successfully');
        this.dialogRef.close(true);
      });
    }
    
  }
  