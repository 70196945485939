import { Component, OnInit } from '@angular/core';
import { MatDatepicker, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { DatabaseService } from 'src/app/_services/DatabaseService';
import { SessionStorage } from 'src/app/_services/SessionService';
import { ProductImageModuleComponent } from '../master/product-image-module/product-image-module.component';

@Component({
    selector: 'app-coupon-code-master',
    templateUrl: './coupon-code-master.component.html',
    styleUrls: ['./coupon-code-master.component.scss']
})
export class CouponCodeMasterComponent implements OnInit {
    
    loading: any;
    source: any = '';
    loading_page = false;
    loading_list = false;
    loader: any = false;
    locations: any = [];
    products:any = [];
    total_products:any = 0;
    last_page: number ;
    current_page = 1;
    search: any = '';
    searchData = true;
    isInvoiceDataExist = false;
    filter:any = {};
    filtering : any = false;
    savingData = false;
    productForm: any = {};
    image = new FormData();
    uploadurl:any='';
    addImageIcon=true;
    mainCategory:any=[];
    sr_no:any=0;
    constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router, public ses: SessionStorage,public dialog: DialogComponent, public alrt:MatDialog ) {}
    
    ngOnInit() {
        this.getProductList('');
        this.productForm.image=[];
        this.mainCategory.profile_selected = 0;
        this.uploadurl = this.db.uploadUrl;
    }
    
    openDatePicker(picker : MatDatepicker<Date>)
    {
        picker.open();
    }
    
    redirect_previous() {
        this.current_page--;
        this.getProductList('');
    }
    redirect_next() {
        if (this.current_page < this.last_page) { this.current_page++; }
        else { this.current_page = 1; }
        this.getProductList('');
    }

    openDialog(id, string) {
        const dialogRef = this.alrt.open(ProductImageModuleComponent, {
          data: {
            id: id,
            mode: string,
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          console.log(`Dialog result: ${result}`);
        });
      }
    
    currentPage = () => {
        if(this.current_page < 1){
            this.current_page = 1;
        }else if (this.current_page > this.last_page) 
        { 
            this.current_page = this.last_page;
        }
        this.getProductList('');
    }
    
    getProductList(action) 
    {
        this.loading_list = true;
        this.filter.date = this.filter.date  ? this.db.pickerFormat(this.filter.date) : '';
        if( this.filter.date || this.filter.location_id )this.filtering = true;
        this.filter.mode = 0;
        
        if(action=='refresh')
        {
            this.filter={};
        }
        console.log(this.filter);
        
        this.db.post_rqst({'filter': this.filter , 'login':this.db.datauser}, 'master/productPointsList?page=' + this.current_page )
        .subscribe( d => {
            console.log(d);
            this.loading_list = false;
            this.current_page = d.product_point.current_page;
            this.last_page = d.product_point.last_page;
            this.total_products =d.product_point.total;
            this.products = d.product_point.data;
            this.sr_no = this.current_page - 1;
            this.sr_no = this.sr_no * d.product_point.per_page;    
            // this.productForm =  this.products;
        });
    }
    
    catdata:any='';
   
    editProduct(id,index){
        this.addImageIcon=true;
        this.productForm = this.products.filter( x => x.id==id)[0];
        console.log(this.productForm);

        this.productForm.image = this.uploadurl + this.productForm.image;
        console.log(this.productForm.image);
        
        
        this.productForm.profile_selected = parseInt(this.productForm.profile);
        console.log(this.productForm);
        this.productForm.profile_selected = 0;
        
    }
    toggle:any;
    saveProduct()
    {
        this.productForm.last_updated_by = this.db.datauser.id;
        this.savingData = true;
        this.db.post_rqst( this.productForm, 'master/updateProductPoints')
        .subscribe( d => {
            if(d.status == 'GROUPEXIST' || d.status == 'PRODUCTEXIST'){
                this.dialog.error('Product Point Group already exists.');
                this.savingData = false;
                return;
            }
            console.log( d );
            this.savingData = false;
            this.productForm = {};
            this.toggle = "false";
            this.router.navigate(['coupon-code-master']);
            this.dialog.success('Product successfully save');
            this.getProductList('');
            
        });
    }
    
    
    
    
    active:any='';
    ProductProfile(index,img_id)
    {
        this.active=index;
        this.productForm.profile_selected=img_id;
    }
    
    addProduct()
    {
        this.productForm={};
        this.addImageIcon=true;
    }
    
    deleteProduct(id, product_id) {
        console.log("====================================");
        console.log(id, product_id);
        console.log("====================================");
        this.dialog.delete("Product").then((result) => {
            if (result) {
                this.db
                .post_rqst({ product_id: product_id, id: id }, "master/productgroupDelete")
                .subscribe((d) => {
                    console.log(d);
                    this.getProductList("");
                    this.dialog.successfully();
                });
            }
        });
    }
    
    
    exportproductList()
    {
        this.filter.mode = 1;
        this.db.post_rqst(  {'filter': this.filter , 'login':this.db.datauser}, 'master/exportProductPointsList')
        .subscribe( d => {
            this.loading_list = false;
            document.location.href = this.db.myurl+'app/uploads/exports/ProductPoints.csv';
            console.log(d);
        });
    }
    
    onUploadChange2(evt: any) {
        const file = evt.target.files[0];
        console.log('File Property ->', file.size);
        if(file.size > 1014406) {
            this.dialog.error('Image size more than 1 Mb is not allowed.');
            return;
        }
        
        if (file) {
            const reader = new FileReader();
            reader.onload = this.handleReaderLoaded2.bind(this);
            reader.readAsBinaryString(file);
        }
    }
    
    
    handleReaderLoaded2(e) {
        this.productForm.image = 'data:image/png;base64,' + btoa(e.target.result) ;
    }
    
    copyDealerPoint = (e) => {
        console.log(e.checked);
        
        if(e.checked == true){
            this.productForm.painter_point = this.productForm.dealer_point 
        }
        else {
            this.productForm.painter_point = ''; 
        }
        
    }
    
}

