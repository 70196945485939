import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepicker, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../dialog/dialog.component';
import { DatabaseService } from '../_services/DatabaseService';

@Component({
  selector: 'app-point-settlement',
  templateUrl: './point-settlement.component.html',
  styleUrls: ['./point-settlement.component.scss']
})
export class PointSettlementComponent implements OnInit {
  paymentData:any ={};
  status_type:any={};
  type:any={};
  reason:any ={};
  id: any = [];
  loading_list:any = false;
  savingData = false;
  
  karigar_id;
  date1:any;
  modelValue:any ;


  constructor(public router:ActivatedRoute, private route: Router,public dialogRef: MatDialog,public dialog: DialogComponent, public alrt:MatDialog,public db:DatabaseService,@Inject(MAT_DIALOG_DATA) data, ) {
    console.log(data);
    this.modelValue =  data;
    console.log(this.modelValue.id);
         
    this.date1 = new Date();
  }

  openDatePicker(picker : MatDatepicker<Date>)
  {
      picker.open();
  }

  ngOnInit() {
  }

  submit(form:any)
    {

      if(this.paymentData.points > this.modelValue.point){
        this.dialog.error('You have Insufficient points in your wallet');
        return
      }

      this.paymentData.id = this.modelValue.id;
      this.paymentData.trans_date = this.paymentData.trans_date  ? this.db.pickerFormat(this.paymentData.trans_date) : '';

      this.savingData = true;
      this.db.post_rqst({ 'data':this.paymentData}, 'karigar/point_settlement')
      .subscribe(d => {
        console.log(d);

        if(d.status == 'SUCCESS'){
          this.dialogRef.closeAll();
          this.dialog.success( 'Point Settled successfully');
          this.route.navigate(['dealer-list/1']);
        }
       
      });
     
    }

}
